.color-blue{
	color: #0AAFFF;
}
.color-orange{
	color: #FFC002;
}
.figure{
	position: absolute;
	z-index: 1;
}
.section-figure__one{
	left: 17%;
	top: 40%;
}
.section-figure__two{
	left: 30%;
	top: 80%;
}
.section-figure__three{
	left: 70%;
	top: 30%;
}
.section-figure__four{
	left: 38%;
	top: 65%;
}
.banner-figure__one{
	left: 35%;
	top: 69%;
}
.banner-figure__two{
	left: 39%;
	top: 34%;
}
.slate-gray{
	color: #748394 !important;
}
.has-error{
	border: 1px solid #F50C0C !important;
}
.has-success{
	border: 1px solid #5D8ED6 !important;
}
.has-error__text{
	font: 600 1.4rem/2.3rem $base-font;
	color: #F50C0C;
	position:absolute;
	text-align: right;
	margin-bottom: -2.3rem;
	right: 0;
}