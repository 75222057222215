$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon" !default;

$arrow-left: "\e900";
$arrow-right: "\ea3c";  
$icon-play: "\ea1c";
$arrow-down: "\ea43";
$facebook: "\ea90";
$twitter: "\ea96";
$linkedin: "\eaca";
$eye: "\e9ce";
$right-redo: "\e968";
$star: "\e9d9";
$icon-notification: "\ea08";
@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.arrow-down{
  &:before {
    content: $arrow-down;
  }
}
.icon-play{
  &:before{
    content: $icon-play;
    display: inline-block;
    position: absolute;
    font-size: 1.6rem;
    height: 1.6rem;
    left: 2.3rem;
    top: 2.1rem;
  }
}
.facebook {
  &:before {
    content: $facebook;
  }
}
.icon-notification{
  position: absolute;
  right: 1.5rem;
  font-size: 2rem;
  top: 5.8rem;
  color: #F50C0C;
  &:before {
    content: $icon-notification;
  }
}
.twitter {
  &:before {
    content: $twitter;
  }
}
.arrow-right{
  &:before{
    content: $arrow-right;
  }
}
.linkedin{
  &:before{
    content: $linkedin;
  }
}
.star{
  color: #FFC002;
  &:before{
    content: $star;
  }
}
.view{
  &:before{
    content: $eye;
  }
}
.right-redo{
  &:before{
    content: $right-redo;
  }
}