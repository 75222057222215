@media screen and (max-width: 1250px){
	.owl-theme .owl-dots{
		bottom: 5.5rem;
	}
	.main-header-partner__form{
		justify-content: space-around;
	}
}
@media screen and (max-width: 1200px){
	.trusted-section__row{
		justify-content: space-around;
	}
	.trusted-section__col{
		width: 29rem;
	}
	.wrapper-btn{
		display: block;
	}
	.index-section__btn{
		margin: 3rem 0 0;
	}
	.main-header__start-btn{
		margin-right: 2rem;
	}
	.howwork-section__col:nth-child(2n-1)::after,
	.howwork-section__col:nth-child(2n)::after{
		margin-left: 5.5rem;
	}
	.banner-section__col-text{
		padding-right: 5rem;
	}
	.similar-articles__wrap{
		justify-content: space-around;
	}
	.similar-article{
		margin-right: 0;
	}
	.main-header-partner .section-figure__four{
		top: 31%;
	}
	.main-header-partner .section-figure__one{
		left: 8%;
		top: 22%;
	}
}
@media screen and (max-width: 1120px){
	.owl-theme .owl-dots{
		bottom: 0rem;
	}
}
@media screen and (max-width: 1024px){
	.all-content__wrap{
		flex-direction: column;
		align-items: center;
	}
	.all-content__left{
		padding-right: 0;
		max-width: 100%;
	}
	.block-aside{
		width: 100%;
		&__col{
			padding: 9rem 6.3rem;
		}
	}
	.index-section{
		padding: 0;
		.index-image__img{
			width: 55rem;
			height:51.2rem;
		}
	}
	.howwork-section__col:nth-child(2n-1)::after,
	.howwork-section__col:nth-child(2n)::after{
		margin-left: 2.5rem;
	}
	.howwork-section__col{
		width: 19rem;
	}
	.index-section__title{
		font-size: 4.5rem;
	}
	.banner-section__row, .worldwide-section__row{
		flex-direction: column;
	}
	.banner-section__col-wrap-img{
		margin: 0 0 -15.5rem;
		text-align: center;
	}
	.banner-section__col:first-child, .worldwide-section__col{
		width: 100%;
	}
	.banner-section .wrapper-h2__text{
		margin-bottom: 2rem;
	}
	.banner-section__col-text{
		padding-right: 0;
		margin-bottom: 5rem;
	}
	.worldwide-section{
		padding: 14.2rem 0;
		&__reviews{
			margin: 6rem auto 0;
		}
	}
	.main-header__start-btn{
		padding: 1.4rem 2.9rem;
		margin-right: 1rem;
	}
	.main-header__wrap .block-logo{
		height: 2.6rem;
		img{
			width: 15rem;
			height: 2.6rem;
		}
	}
	.main-header__navigation-list{
		font-size: 1.4rem;
		&-link{
			padding-left: 2.6rem;
		}
	}
	.block-aside__col:not(:last-child){
		margin-bottom: 0rem;
	}
	.block-aside__col:first-child{
		margin-top: 4rem;
	}
	.worldwide-section__reviews{
		max-width: 100%;
	}
	.owl-theme .owl-dots{
		position: static;
	}
	.owl-theme .owl-nav.disabled + .owl-dots{
		margin-top: 6rem;
	}
	.worldwide-section__reviews{
		margin: 3rem auto 0;
	}
	.block-form{
		width: 43.6rem;
		padding: 4.8rem 4.3rem;
	}
	.main-input__label-input{
		width: 35rem;
	}
	.index-section-form {
	    padding: 2.5rem 0 14rem;
	}
	.block-form-forgot .main-btn--small{
		padding: 1.5rem 5.5rem;
	}
	.block-aside-faq{
		margin: 0 0 6rem 0;
		.block-faq__col{
			width: 100%;
		}
	}
	.section-our-partners__row{
		flex-direction: column;
		align-items: center;
	}
}
@media screen and (max-width: 992px){
	.connect-section__col{
		width: 46%;
		padding: 6rem 2% 0;
	}
	.wrapper-h2__title{
		font-size: 3.4rem;
	}
	.main-footer__col:not(:first-child){
		padding-left: 5.4%;
	}
	.connect-section, .howwork-section, .trusted-section, .banner-join-section,
	.platform-section{
		padding: 10rem 0;
	}
	.worldwide-section{
		padding: 14.2rem 0 10rem;
	}
	.similar-articles{
		padding-top: 0;
	}
}
@media screen and (max-width: 800px){
	.howwork-section__col{
		width: 48%;
		&:nth-child(2n-1), &:nth-child(2n){
			padding-top: 6rem;
			&:after{
				display: none;
			}
		}
	}
	.all-content{
		padding: 10rem 0;
	}
	.main-header{
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		box-shadow: 0rem .4rem .4rem rgba(0, 0, 0, 0.05);
		padding: 0.8rem 0;
		background: white;
		z-index: 100;
		padding: 1rem 0 1.1rem;
		&__start{
			display: none;
		}
		&__hamburger{
			display: block;
		}
		&__navigation{
			background: #fff;
			box-sizing: border-box;
			position: absolute;
			top: 4rem;
			left: 0;
			opacity:0;
			transition: all .2s ease-in-out;
			width: 100%;
			padding: 2.5rem 2rem 0 2rem;
			height: 100vh;
			z-index: -1;
			pointer-events: none;
			&-list{
				display: block;
				font-size: 1.6rem;
				&-item{
					border-bottom: 1px solid rgba(0,0,0,.2);
					&:last-child{
						border-bottom: none;
					}
				}
				&-link{
					display: block;
					text-align: left;
					color: #666;
					padding: 1.65rem 1.5rem 1.65rem 1.5rem !important;
					letter-spacing: .02em;
					font-weight: normal;
					text-transform: uppercase;
				}
			}
			&-btn{
				display: block;
			}
		}
		.menu_active{
			opacity: 1;
			pointer-events: auto;
		}
	}
	.index-section{
		padding-top: 12rem;
		&__title{
			margin-bottom: 3.4rem;
		}
		.wrapper-btn{
			margin-top: 3.5rem;
		}
	}
	.index-image{
		margin: 0 0 5rem;
	}
	.index-row{
		flex-direction: column;
	}
	.index-col{
		max-width: 100%;
		order: 2;
	}
	.big-container, .test-section-article .big-container{
		min-height: auto;
		padding: 6rem 0;
	}
	.index-section-form{
		padding-top: 12rem;
		.index-col{
			order: 0;
			padding-top: 0;
		}
	} 
	.block-form{
		margin: 0;
	}
	.wrapper-form{
		margin: 6rem auto 0;
	}
	.main-header-partner__form{
		flex-direction: column;
		width: 35rem;
	}
	.main-header-partner{
		padding-top: 9rem;
		.banner-join-section__text{
			margin-bottom: 6rem;
		}
	}
	.index-section-partner{
		.index-row{
			align-items: center;
		}
		&__icon{
			margin-bottom: 7.5rem;
		}
		&__icon-2{
			margin-right: 0;
		}
	}
	.apps-section{
		padding: 10rem 0;
		&__col:first-child{
			margin-right: 0;
		}
		&__row{
			flex-wrap: wrap;
		}
		&__col{
			width: 100%;
		}
	}
	.support-section{
		padding-bottom: 0;
	}
	.who-partner-section{
		padding-bottom: 10rem;
	}
	.index-section-partner{
		padding-bottom: 10rem;
		&__icon{
			margin-bottom: 6rem;
		}
	}
	.section-our-partners{
		padding: 0rem 0 10rem;
		&__row .block-partner{
			padding: 4rem 4.1rem 0;
		}
	}
	.main-header--blue .main-header__navigation-list-link, .main-header--blue .main-header__start-link{
		color: #666;
	} 
}
@media screen and (max-width: 768px){
	.platform-section__row{
		flex-direction: column;
	}
	.platform-section__col-brands{
		padding-top: 0;
	}
	.platform-section__col{
		width: 100%;
		&:first-child{
			margin-bottom: 5rem;
		}
		&:last-child{
			max-width: 100%;
		}
	}
	.main-footer__col:not(:first-child)::before{
		display: none;
	}
	.wrapper-form-forgot{
		margin: 0 auto;
	}
}
@media screen and (max-width: 760px){
	.worldwide-section__review {
		margin: 0 auto !important ;
	}
}
@media screen and (max-width: 740px){
	.banner-section__col-wrap-img{
		padding: 2rem 0;
		img{
			width: 100%;
			height: auto;
		}
	}
	.banner-section__col:last-child{
		max-width: 100%;
	}
	.section-figure__one{
		left: 87%;
		top: 15%;
	}
	.section-figure__two{
		left: 30%;
		top: 86%;
	}
	.section-figure__three{
		left: 89%;
		top: 49%;
	}
	.section-figure__four{
		left: 9%;
		top: 84%;
	}
	.banner-section__col-wrap-img{
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 30px;
		height: 0;
		overflow: hidden;
		iframe{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
	.banner-figure__one{
		left: 81%;
		top: 6%;
	}
	.banner-figure__two{
		left: 75%;
		top: 35%;
	}
	.main-header-partner .section-figure__three{
		top: 35%;
		left: 90%;
	}
	.section-figure__two{
		top: 93%;
	}
	.main-header-partner .section-figure__one{
		left: 79%;
		top: 9%;
	}
	.main-header-partner .section-figure__four{
		top: 46%;
		left: 1%;
	}
}
@media screen and (max-width: 640px){
	.block-article{
		max-width: 100%;
	}
	.trusted-section__col{
		width: 48%;
	}
	.index-section .index-image__img{
		width: 100%;
		height: auto;
	}
	.main-footer{
		padding: 6.3rem 0 3.5rem;
		&__col{
			width: 50%;
			margin-bottom: 3rem;
			padding: 0 !important;
			&:first-child{

			}
		}
		&__wrap{
			flex-wrap: wrap;
		}
		.block-logo{
			margin-bottom: 2rem;
			height: 2.6rem;
			img{
				width: 15.6rem;
				height: 2.6rem;
			}
		} 
	}
	.content-text p+div{
		padding: 5rem 3rem 3.7rem 2.5rem;
		>div{
			padding-right: 5%;
		}
	}
}
@media screen and (max-width: 480px){
	.wrapper-h2__title{
		font-size: 2.4rem;
	}
	.connect-section__col{
		padding: 2.5rem 0 0;
		width: 100%;
		&-wrap-icon{
			width: 5rem;
			height: 5rem;
			margin-bottom: 2rem;
		}
		&-title{
			font-size: 2rem;
			min-height: auto;
		}
		&-text{
			font-size: 1.6rem;
			margin-bottom: 0;
		}
	}
	.howwork-section__col-icon{
		width: 8rem;
		height: 8rem;
		margin-bottom: 2rem;
		&:before{
			font-size: 4rem;
		}
	}
	.howwork-section__col-text{
		font-size: 1.6rem;
	}
	.other-section{
		padding: 3rem 0 7.4rem;
	}
	.connect-section, .banner-join-section, .trusted-section{
		padding: 8rem 0;
	}
	.index-section__title{
		font: 900 3.5rem/4.5rem $base-font;
		margin-bottom: 2rem;
	}
	.index-section__btn{
		font-size: 2rem;
	}
	.main-btn--small{
		padding: 1.5rem 3.5rem;
		font-size: 2rem;
	}
	.block-circle{
		width: 4rem;
		height: 4rem;
		margin-right: 1rem;
	}
	.connect-section__p{
		font-size: 1.6rem;
	}
	.icon-play:before{
		left: 1.1rem;
		top: 1.1rem;
	}
	.howwork-section .wrapper-h2__title, .trusted-section .wrapper-h2__title,
	.all-content .wrapper-h2__title{
		margin-bottom: 0;
	}
	.all-content{
		padding: 8rem 0;
	}
	.block-article__link-img{
		margin-bottom: 1rem;
	}
	.block-article__link-title{
		font-size: 2rem;
	}
	.block-article__wrap-block{
		margin: 2rem 0 4rem;
		font-size: 1.2rem;
		max-width: 100%;
	}
	.test-section__block:not(:first-child)::before{
		display: none;
	}
	.test-section__block:not(:first-child){
		padding-left: 0;
	}
	.all-content__wrap{
		padding-top: 2rem;
	}
	.test-section__social-link{
		padding: 0 .5rem;
	}
	.section__wrap-btn{
		padding-top: 4rem;
	}
	.trusted-section__col{
		width: 100%;
	}
	.howwork-section__col{
		width: 100%;
		padding-top: 6.4rem !important;
		&:nth-child(2n-1)::after ,&:nth-child(2n)::after{
			display: block;
			background: url(../img/howwork_line.svg) no-repeat !important;
			top: 0;
			margin-left: 0;
			left: 50%;
			top: 1.3rem;
			height: 3.5rem;
		}
		&:first-child{
			padding-top: 4rem !important;
			&:after{
				display: none;
			}
		}
	}
	.platform-section{
		padding: 6rem 0;
	}
	.banner-section{
		padding: 3rem 0 7.5rem;
	}
	.banner-section .wrapper-h2{
		margin-bottom: 2rem;
	}
	.banner-section__col-text{
		font-size: 1.6rem;
	}
	.banner-section__col-text{
		margin-bottom: 4rem;
	}
	.main-footer{
		padding: 3rem 0 0rem;
		&__col-list-link{
			font-size: 1.4rem;
		}
		&__col-title{
			margin-bottom: 1.5rem;
		}
		.block-logo{
			height: 2rem;
			img{
				width: 10.6rem;
				height: 2rem;
			}
		}
		&__social-link{
			font-size: 1.6rem;
			padding: .8rem;	
		}
	}
	.block-aside__col{
		margin: 0  -2rem;
		padding: 3rem 2rem;
	}
	.block-aside__text{
		font-size: 1.6rem;
	}
	.block-aside__title{
		font-size: 2rem;
	}
	.big-container, .test-section-article .big-container{
		padding: 1rem 0;
		&__img{
			position: inherit;
			width: 100%;
			height: auto;
			margin-bottom: 2rem;
		}
	}
	.test-section .index-section__title{
		font: 900 2.4rem/3.2rem $base-font;
	}
	.test-section__wrap-btn{
		margin: 3rem 0 0;
	}
	.content-text{
		h3{
			font-size: 2rem;
			margin: 3rem 0 1.5rem;
		}
		p{
			font-size: 1.6rem;
		}
		p+div{
			padding: 2rem 3rem 0.7rem 2.5rem;
			>div >div{
				padding-left: 1rem;
				a{
					font-size: 1.4rem;
					padding-right: 0;
				}
			}
		}
		ul{
			padding-top: 0rem;
			margin: 0 0 2rem 1rem;
			li{
				font-size: 1.6rem;
			}
		}
		blockquote{
			font-size: 1.6rem;
			padding: 1.5rem 2rem 1.5rem 2rem;
		} 
	}
	.test-section__wrap-block{
		max-width: 100%;
	}
	.similar-articles{
		padding-top: 0;
	}
	.all-content-article{
		padding-top: 7rem;
		&__wrap{
			padding-top: 1rem;
		}
	}
	.worldwide-section{
		padding: 15.2rem 0 5rem;
		&_reviews{
			margin: 0 auto;
		}
		&__reviews{
			margin: 0 auto;
		}
		&__review{
			padding: 3rem 4.7rem 4rem 2rem;
			max-width: 100%;
			&-rating{
				margin-bottom: 1.2rem;
				i{
					font-size: 2rem;
				}
			}
			&-text{
				margin-bottom: 2rem;
			}
		}
	}
	.main-container{
		padding: 0 2rem;
	}
	.index-section__paragraph{
		margin-bottom: 1rem;
	}
	.banner-section .wrapper-h2__title{
		margin-bottom: 2rem;
	}
	.banner-join-section__text{
		padding-top: 0;
	}
	.banner-join-section .section__wrap-btn{
		padding-top: 5rem;
	}
	.owl-theme .owl-nav.disabled + .owl-dots{
		margin-top: 2rem;
	}
	.content-article__list{
		padding-top: 0;
	}
	.index-col .test-section__wrap-block,
	.block-article__wrap-block .block-article__social:first-child,
	.similar-article__wrap-block .block-article__social:first-child{
		display: none;
	}
	.big-container, .test-section-article .big-container{
		margin: 4rem auto 0;
	}
	.block-article__wrap-block .block-article__social:last-child,
	.similar-article__wrap-block .block-article__social:last-child{
		font-size: 1.8rem;
	}
	.content-article__wrap-info{
		font-size: 1.8rem;
	}
	.similar-article__wrap-block{
		margin: 1rem 0 0;
	}
	.block-article__link .similar-article__wrap-block{
		display: block;
	}
	.all-content__pagination{
		margin: 5rem auto 0;
	}
	.similar-article{
		padding: 4rem 0 0;
		.similar-article__wrap-block{
			margin-top: 2.1rem;
		}
		.block-article__link .similar-article__wrap-block{
			margin-top: 1.1rem;
		} 
	}
	.all-content__left .block-article__link  .block-article__social{
		display: block;
		padding-left: 0;
		font: 900 1.2rem/1.1rem $base-font;
		letter-spacing: 0.1em;
		color: #5D6E83;
		opacity: .5;
		text-transform: uppercase;
		margin: 1rem 0 2rem;
		&:before{
			display: none;
		}
	}
	.all-content__left .block-article__link .similar-article__wrap-block .block-article__social{
		opacity: 1 ;
	}
	.index-section-form .index-col{
		display: none;
	}
	.block-form{
		box-shadow: none;
		padding: 0;
		width: 100%;
	}
	.main-input__label-input{
		width: 28rem;
		height: 5rem;
	}
	.index-section-form{
		padding: 8rem 0 6rem;
	}
	.wrapper-form{
		width: 28rem;
		margin: 0 auto;
	}
	.section__wrap-btn{
		padding-top: 2rem;
	}
	.block-form__wrap-btn .main-btn--small{
		padding: 1.5rem 5rem;
	}
	.main-input__label-text{
		margin-bottom: .5rem;
		font-size: 1.2rem;
	}
	.block-form__col{
		margin-bottom: 1.2rem;
		&-text{
			font-size: 1.4rem;
		}
	}
	.block-form__text{
		margin-bottom: 2.5rem;
		font-size: 1.6rem;
		line-height: 2.6rem;
	}
	.block-form .block-form__title{
		margin-bottom: .5rem;
	}
	.block-form__wrap-btn .main-btn--small{
		padding: 1rem 4.5rem;
	}
	.wrapper-form-forgot .block-form__text{
		font-weight: 600;
	}
	.block-form-forgot .block-form__wrap-btn{
		padding-bottom: 0;
	}
	.icon-notification{
		font-size: 1.7rem;
		top: 4.8rem;
	}
	.all-content .index-section__title{
		font-size: 2.4rem;
		line-height: 3.2rem;
		margin-bottom: 0;
	}
	.all-content-article .index-section__title{
		margin-bottom: 2.7rem;
	}
	.content-article__img{
		margin-bottom: 1.5rem;
	}
	.all-tearms__wrap{
		padding-top: 0;
	}
	.content-tearms__title{
		font: 900 2rem/2.6rem $base-font;
		margin-bottom: 5rem;
	}
	.content-tearms__list{
		margin-bottom: 3rem;
		li{
			margin-bottom: 1rem;
		}
	}
	.content-tearms__title{
		margin-bottom: 2rem;
	}
	.all-tearms{
		padding-bottom: 0;
	}
	.block-aside-faq{
		margin: 3rem 0 3rem;
	}
	.content-faq__text{
		h3{
			font: 900 2rem/2.6rem $base-font;
			margin-bottom: 1.2rem;
		}
		p{
			margin-bottom: 2rem;
		}
		ul+h3{
			margin-bottom: 2rem;
		}
		ul{
			margin: 0rem 0 2.5rem;
		}
	} 
	.all-faq{
		padding: 8rem 0 4rem;
	}
	.block-aside-faq{
		.block-faq__col-list{
			position:relative;
			padding-top: 5rem;
			border: .1rem solid rgba(177, 182, 189, 0.6);
			box-shadow: none;
			width: 100%;
			&-item{
				height: 5rem;
				overflow:hidden;
				padding: 0 1.4rem;
				box-sizing: border-box;
				&:hover{
					background: white;
					a{
						color: #5D6E83;
					}
				}
			}
			&-link{
				line-height: 5rem;
			}
			.block-faq__col-list-item:not(.current-item){
				max-height: 0;
				box-sizing: border-box;
				transition: max-height .5s;
				overflow: hidden;
			}
		}
		.current-item{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			background: #fff;
			a{
				color: #5D6E83;
			}
			&:before{
				content: '';
				position: absolute;
				top: 50%;
				right: 2rem;
				margin-top: -.3rem;
				background: url(../img/arrow_dropdown.png) no-repeat;
				width: 1.1rem;
				height: .6rem;
			}
		}
		.active .current-item::before{
			transform: rotate(180deg);
		}
		.active .block-faq__col-list-item{
			max-height: 5rem !important;
			&:not(:last-of-type){
				border-bottom: .1rem solid #e6e4e4;
			}
		}
	}
	.index-section{
		padding-top: 9rem;
	}
	.index-section-partner__icon img{
		width: 100%;
		height: auto;
	}
	.main-header-partner{
		text-align: left;
		.banner-join-section__text{
			font-size: 1.6rem;
			font-weight: normal;
			margin-bottom: 3rem;
		}
	} 
	.apps-section{
		padding: 6rem 0 8rem;
		.wrapper-h2__title{
			margin-bottom: 2rem;
		}
		&__col{
			padding: 3rem;
			margin-top: 1rem;
			&-logo{
				margin-bottom: 2.5rem;
			}
			&-title{
				font: 900 2rem/2.6rem $base-font;
			}
			&-text{
				font: 1.6rem/2.4rem $base-font;
			}
			&:first-child{
				margin-bottom: 2rem;
			}
		}
	}
	.support-section{
		.wrapper-h2__title{
			margin-bottom: 2rem;
		}
		&__text{
			font: 600 1.6rem/2.6rem $base-font;
		}
		.section__wrap-btn{
			padding: 2rem 0 0;
		}
	}
	.who-partner-section, .index-section-partner{
		text-align: left;
		padding-bottom: 6rem;
	}
	.index-section-partner{
		.wrapper-h2__title{
			margin-bottom: 2rem;
		}
		&__paragraph{
			font: 600 1.6rem/2.6rem $base-font;
		}
	}
	.index-section-partner__icon{
		margin-bottom: 4.5rem;
	}
	.section-our-partners{
		padding: 0rem 0 8rem;
		.wrapper-h2__title{
			margin-bottom: 1rem;
		}
		&__row .block-partner{
			padding: 3rem 0 0;
			img{
				width: 100%;
				height: auto;
			}
		} 
		&__col{
			width: 100%;
			justify-content: space-between;
		}
	}
	.main-header-partner .block-form__wrap-btn{
		padding-bottom: 1.3rem;
	}
}
@media screen and (max-width: 450px){
	.content-text p+div{
		flex-direction: column;
		>div >div{
			min-height: auto;
		}
	}
}
@media screen and (max-width: 320px){
	.howwork-section__col{
		width: 23.2rem;
	}
	.all-content__wrap{
		padding-top: 2.5rem;
	}
	.all-content-article__wrap{
		padding-top: 1rem;
	}
	.howwork-section__row{
		justify-content: center;
	}
	.index-section{
		padding-top: 8rem;
		&__btn{
			margin-top: 2rem;
		}
		.wrapper-btn{
			margin-top: 3rem;
		}
		&__title{
			font: 900 2.8rem/3.2rem $base-font;
		}
	}
	.index-image{
		margin: 0 0 4rem;
	}
	.trusted-section__col-text, .trusted-section__col-rating{
		font-size: 1.6rem;
	}
	.banner-join-section .wrapper-h2__title{
		margin-bottom: 2rem;
	}
	.banner-join-section__text{
		font-size: 1.6rem;
	}
	.banner-join-section::after{
		width: 23.9rem;
		height: 23.9rem;
		margin-left: -25rem;
		bottom: 39rem;
	}
	.banner-join-section::before{
		width: 39rem;
		height: 39rem;
		margin-left: 0.2rem;
		top: 38rem;
	}
	.big-container, .test-section-article .big-container{
		padding: 0;	
	}
	.similar-article__wrap-block{
		display: none;
	}
	.block-article__link .similar-article__wrap-block{
		display: block;
	}
	.similar-article .block-article__link-img{
		width: 8rem;
		height: 8rem;
		float: left;
		margin-right: 1.8rem;
	}
	.similar-article{
		padding: 1.5rem 0 0;
	}
	.similar-article .block-article__link-title{
		font: bold 1.6rem/2.1rem $base-font;
	}
	.block-article__link .similar-article__wrap-block{
		font: 900 1.2rem/1.1rem $base-font;
		margin: 0 0 .6rem;
	}
	.similar-articles__wrap{
		margin-top: 2rem;
	}
	.all-content-article{
		padding: 7rem 0 5rem;
	}
	.similar-article__wrap-block .block-article__social{
		margin: 0 !important;
	}
	.all-tearms__wrap{
		padding-top: 0;
	}
}




