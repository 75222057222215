.main-btn{
	display: inline-block;
	padding: 1.8rem 5.9rem;
	border-radius: 4.4rem;
	border: 2px solid #FFC002;
	box-shadow: 0px 4px 25px rgba(125, 156, 165, 0.07);
	box-sizing: border-box;
	cursor: pointer;
	outline: none;
	color: #FFC002;
	letter-spacing: 0.008em;
	font-weight: 600;
	&:hover{
		background: #FAD02D;
		color: white;
		border: 2px solid #FAD02D;
	}
	&:focus{
		background: #FFA800;
		border: 2px solid #FFA800;
		color: white;
	}
}
.main-btn--small{
	padding: 1.5rem 5.5rem;
	font: 800 2.4rem/1.25 $base-font;
	letter-spacing: 0.004em;
}
.main-btn--orange{
	background: #FFC002;
	color: white;
	border: none;
	&:hover, &:focus{
		border: none;
	}
}
.main-btn--blue{
	background: #0AAFFF;
	color: white;
	border: none;
	&:hover, &:focus{
		border: none;
	}
}
.wrapper-btn{
	display: flex;
	margin-top: 6.5rem;
}
.block-circle{
	position: relative;
	width: 6rem;
	height: 6rem;
	border: .2rem solid #FFC002;
	box-sizing: border-box;
	box-shadow: 3rem .4rem 7rem rgba(16, 86, 122, 0.12);
	border-radius: 50%;
	margin-right: 1.5rem;
}
.section__wrap-btn{
	padding: 8rem 0 2.3rem;
	text-align: center;
}

