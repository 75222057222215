html{
	font-size: 62.5%;
	line-height: 1.5;
}
.main-wrapper{
	position: relative;
	margin: 0 auto;
	overflow: hidden;
	font: 1.6rem $base-font;
	color: white;
	background: white;
}
.main-container{
	@include container();
	max-width: 137.4rem;
}
.big-container{
	position: relative;
	padding: 9.5rem 0 6rem;
	max-width: 150rem;
	margin: 2.5rem auto 0;
	box-sizing: border-box;
	min-height: 60rem;
	&__img{
		position:absolute;
		top: 0;
	}
}
.wrapper-h2{
	position: relative;
	text-align: center;
	&__text{
		font: 900 1.2rem $base-font;
		color: #FFC002;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		margin-bottom: 2rem;
	}
	&__title{
		font: 900 4.4rem $base-font;
		letter-spacing: 0.005em;
		color: #5D6E83;
		margin-bottom: 3rem;
	}
}
.main-header{
	padding: 2.6rem 0 4.5rem;
	&__navigation-list{
		display: flex;
		justify-content: space-between;
		font: bold 1.6rem/2rem $base-font;
		&-link{
			position: relative;
			padding-left: 4.6rem;
			color: #292B34;
			&:hover{
				color: #FFC002;
			}
		}
		&-item:first-child &-link{
			padding-left: 0rem;
		}
	}
	&__navigation-btn{
		display: none;
		background: #FFC002;
		width: 100%;
		text-align: center;
		padding: 1.4rem 0;
		font: 800 1.6rem $base-font;
		color: white;
		text-transform: uppercase;
		position: absolute;
		left: 0;
		cursor: pointer;
	}
}
.main-header__wrap{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.main-header--blue{
	position: relative;
	padding-bottom: 15.4rem;
	background: linear-gradient(273.89deg, #0B43B1 5.4%, #2C7BD9 67.25%);
	.main-header__navigation-list-link, .main-header__start-link{
		color: white;
		&:hover{
			color: #FFC002;
		}
	}
}
.main-header-partner{
	position: relative;
	text-align: center;
	background: linear-gradient(273.89deg, #0B43B1 5.4%, #2C7BD9 67.25%);
	padding-bottom: 5.3rem;
	&:before{
		@include back-circle();	
		margin-left: 7.2rem;
		top: 20rem;
	}
	&:after{
		@include back-circle();	
		width: 58rem;
		height: 58rem;
		margin-left: -118rem;
		bottom: 14rem;
	}
	.index-section__title{
		color: white;
		margin-bottom: 1.2rem;
	}
	&__form{
		position: relative;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 0 auto;
		width: 124rem;
		max-width: 100%;
	}
	.main-input__label-text{
		text-align: left;
		color: white;
	}
	.banner-join-section__text{
		margin-bottom: 7rem;
	}
	.block-form__wrap-btn{
		width: 100%;
	}
	.section-figure__one{
		left: 15%;
		top: 34%;
	}
	.section-figure__three{
		top: 24%;
	}
	.section-figure__four{
		left: 40%;
		top: 39%;
	}
}
.block-logo{
	display: inline-block;
	cursor: pointer;
	height: 3.8rem;
}
.main-header__start-link{
	color: #292B34;
	font-weight: bold;
}
.main-header__start-btn{
	margin-right: 7.8rem;
}
.main-header__hamburger{
	position: relative;
	z-index: 100;
	display: none;
	padding: .8rem;
	margin-right: -.8rem;
	cursor: pointer;
	&-line{
		width: 2rem;
		height: .3rem;
		background: #FFC002;
		margin-bottom: .2rem;
		border-radius: .5rem;
		display: block;
		overflow: hidden;
		transition: opacity .35s ease-in,transform .35s ease-in;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
/*-----------------------*/
.index-section{
	padding: 0 0 2rem;
}
.index-row{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.index-col{
	max-width: 67rem;
	width: 100%;
}
.index-section__title{
	font: 900 6rem/6.6rem $base-font;
	color:#5D6E83;
	letter-spacing: 0.008em;
	margin-bottom: 5.4rem;
}
.index-section__paragraph{
	color: #B1B6BD;
	font: 600 1.6rem/2.64rem $base-font;
	margin-bottom: 1rem;
}
.index-section__btn{
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #FFC002;
	font: bold 2.4rem $base-font;		
	letter-spacing: 0.004em;
	margin-left: 5.2rem;
	&:hover .block-circle{
		background: #FFC002;
		.icon-play{
			color: white;			
		}	
	}
}
.index-image{
	margin-right: -18rem;
}
/*----------------------*/
.connect-section{
	padding: 14rem 0;
	&__row{
		display: flex;
		flex-wrap: wrap;
		width: 132rem;
		max-width: 100%;
		margin: 0 auto;
	}
	&__col{
		padding: 6rem 3.7% 0;
		width: 25.8%;
	}
	&__col-wrap-icon{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 7rem;
		height: 7rem;
		border-radius: 50%;
		background: #0AAFFF;
		box-shadow: 1rem 1rem 3rem rgba(23, 135, 192, 0.1);
		margin-bottom: 4rem;
	}
	&__col-title{
		font: 900 2.2rem $base-font;
		color: #5D6E83;
		min-height: 5.8rem;
		margin-bottom: 1.5rem;
	}
	&__col-text{
		color: #748394;
		font: 600 1.8rem/1.65 $base-font;
		margin-bottom: 1.5rem;
	}
	&__p{
		text-align: center;
		font: bold 1.8rem $base-font;
		color: #5D8ED6;
		letter-spacing: 0.005em;
	}
}
/*------------------------------*/
.howwork-section{
	padding: 14rem 0;
	background: #F8F8F8;
	counter-reset: item;
	&__row{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 129rem;
		max-width: 100%;
		margin: 0 auto;
	}
	&__col{
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 23.1rem;
		text-align: center;
		padding-top: 6rem;
		&:nth-child(2n){
			padding-top: 13.2rem;
			&:after{
				content: '';
				position: absolute;
				width: 19.2rem;
				height: 3.25rem;
				margin-left: 7.5rem;
				left: 49%;
				top: 31%;
				background: url(../img/vector_top.svg) no-repeat;
				z-index: -1;
			}
		}
		&:nth-child(2n-1)::after{
			content: '';
			position: absolute;
			width: 19.2rem;
			height: 3.4rem;
			left: 50%;
			margin-left: 8rem;
			top: 16.1rem;
			background: url(../img/vector_down.svg) no-repeat;
			z-index: -1;
		}
		&:last-child{
			padding-top: 7.3rem;
			&:after{
				display: none;
			}
		}
	}
	&__col-icon{
		position: relative;
		background: #FFFFFF;
		box-shadow: 30px 30px 70px rgba(16, 86, 122, 0.1);
		width: 11.8rem;
		height: 11.8rem;
		border-radius: 50%;
		margin-bottom: 2.8rem;
		&:before{
			content: counter(item);
			counter-increment: item;
			color: #5D8ED6;
			position:absolute;
			font: 900 4.2rem $base-font;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&__col-text{
		font: 600 1.8rem/1.65 $base-font;
		color: #7D838C;
	}
	&__wrap-btn{
		padding: 9rem 0 2.5rem;
	}
}
/*-------------------------------*/
.banner-section{
	position: relative;
	background: linear-gradient(275.55deg, #0B43B1 5.4%, #2C7BD9 67.25%);
	padding: 9.8rem 0 10.5rem;
	.wrapper-h2{
		z-index: 2;
		text-align: left;
		margin-bottom: 4rem;
		&__title{
			color: white;
		}
		&__text{
			margin-bottom: 6.2rem;
		}
	}
	&:before{
		@include back-circle();	
		left: 50%;
		top: 16.3rem;
		transform: translate(-85%, 0);

	}
	&__row{
		display:flex;
		width: 129rem;
		max-width: 100%;
	}
	&__col:first-child{
		width: 546px;
	}
	&__col-wrap-img{
		margin-top: -15rem;
	}
	&__col-text{
		font: bold 1.8rem/1.65 $base-font;
		padding-right: 20rem;
	}
}
/*--------------------------------*/
.worldwide-section{
	position: relative;
	z-index: 2;
	background: #F8F8F8;
	padding: 14.2rem 0 15.6rem;
	.wrapper-h2{
		text-align: left;
	}
	&__row{
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	&__col{
		width: 38.4%;
	}
	&__reviews{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 77rem;
	}
	&__review{
		background: white;
		box-sizing: border-box;
		padding: 4rem 5rem 5rem 4.3rem;
		width: 36.8rem;
		&-text{
			font: 1.6rem/1.65 $base-font;
			color: #5D8ED6;
			margin-bottom: 2.6rem;
		}
		&-rating{
			margin-bottom: 1.6rem;
			i{
				font-size: 2.8rem;
			}
		}
		&-logo img{
			width: auto !important;
		}
	}
}
/*------------------------------------*/
.trusted-section{
	position: relative;
	background: white;
	padding: 13.1rem 0 14rem;
	z-index: 1;
	&__row{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		max-width: 130.5rem;
		margin: 0 auto;
		width: 100%;
	}
	&__col{
		padding-top: 6rem;
		display: inline-block;
		text-align: center;
		&-text{
			font: bold 1.8rem $base-font;
			color: #5D6E83;
			margin-bottom: 1.5rem;
		}
		&-wrap-img{
			margin-bottom: 1.7rem;
		}
		&-rating{
			color: #7D838C;
			font: 600 1.8rem $base-font;
			.star{
				margin-right: .5rem;
			}
		}
	}
}
/*-------------------------------------*/
.banner-join-section{
	position: relative;
	padding: 12.7rem 0 14rem;
	background: linear-gradient(275.55deg, #0B43B1 5.4%, #2C7BD9 67.25%);
	text-align: center;
	.wrapper-h2__title, .connect-section__p{
		color: white;
	}
	&__text{
		max-width: 63.1rem;
		margin: 0 auto;
		font:bold 1.8rem/3rem $base-font;
		padding-top: 1rem; 
	}
	.section__wrap-btn{
		padding-top: 6rem;
	}
	&:before{
		@include back-circle();	
		margin-left: 7.2rem;
		top: 27rem;
	}
	&:after{
		@include back-circle();	
		margin-left: -86rem;
		bottom: 43rem;
	}
}
.platform-section{
	position: relative;
	padding: 14rem 0 16.6rem;
	background: white;
	z-index: 2;
	&__row{
		display: flex;
		justify-content: space-between;
		max-width: 115rem;
		width: 100%;
	}
	.wrapper-h2__title{
		text-align: left;
	}
	&__col{
		&-brands{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding-top: 2.5rem;
		}
		&:last-child{
			max-width: 45.7rem;
			width: 100%;
		}
	}
}
/*----------------apps section------------*/
.apps-section{
	position: relative;
	background: white;
	padding: 14rem 0 11rem;
	&__row{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	&__col{
		box-shadow: .1rem .4rem 2.5rem rgba(212, 222, 224, 0.55);
		border-radius: 5rem;
		width: 65.5rem;
		margin-top: 3rem;
		box-sizing: border-box;
		padding: 3.6rem 5rem 4rem 6rem;
		&:first-child{
			margin-right: 4rem;
		}
		&-title{
			font: 900 2.2rem/2.9rem $base-font;
			color: #5D6E83;
			margin-bottom: 2rem;
		}
		&-logo{
			margin-bottom: 3.7rem;
		}
		&-text{
			font: 600 1.8rem/3rem $base-font;
			color: #748394;
			margin-bottom: 2rem;
		}
		&-link{
			font: bold 1.8rem/3rem $base-font;
			color: #5D8ED6;
			letter-spacing: 0.005em;
		}
	}
}
/*--------support section----------------*/
.support-section{
	text-align: center;
	padding-bottom: 7rem;
	&__text{
		font: 600 1.8rem/3rem $base-font;
		color: #748394;
	}
	&__row{
		max-width: 100%;
		width: 85.6rem;
		margin: 0 auto;
	}
	.section__wrap-btn{
		padding-top: 3.1rem;
	}
}
/*----------expect-section-------------*/
.expect-section{
	padding-top: 0;
}
/*-----------who-partner-section------*/
.who-partner-section{
	padding-bottom: 14rem;
	.support-section__row{
		width: 98.5rem;
	}
} 
/*-------------index-section-partner------*/
.index-section-partner{
	position: relative;
	z-index: 1;
	background: white;
	padding-bottom: 6rem;
	.index-row{
		width: 122.4rem;
		max-width: 100%;
		margin: 0 auto;
		align-items: flex-start;
	}
	&__paragraph{
		font: 600 1.8rem/3rem $base-font;
		color: #748394;
	}
	.index-col{
		max-width: 100%;
	}
	&__icon-2{
		margin-right: 7.5rem;
	}
	&__btn{
		padding-top: 4rem;
		text-align: left;
		.main-btn--blue{
			padding: 1.5rem;
		}
	} 
}
/*----------section-our-partners--------*/
.section-our-partners{
	position: relative;
	z-index: 1;
	background: white;
	padding: 8rem 0 14rem;
	&__row{
		max-width: 100.8rem;
		margin: 0 auto;
		display: flex;
		.block-partner{
			padding: 7rem 4.1rem 0;
		}
	}
	&__col{
		display: flex;
	}
}
/*--------------------------------*/
.main-footer{
	position: relative;
	background: #F8F8F8;
	padding: 6.3rem 0 5.5rem;
	&__wrap{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		max-width: 109rem;
	}
	&__col{
		position: relative;
		&-title{
			color: #29304C;
			font-weight: bold;
			margin-bottom: 2.5rem;
		}
		&-list-link{
			display: inline-block;
			width: 100%;
			color: #68727F;
			padding: 0.8rem 0;
			font-weight: 400;
			&:hover{
				color: #FFC002;
			}
		}
		&-list-item:first-child &-list-link{
			padding-top: 0;
		}
		&:not(:first-child){
			padding-left: 7.4%;
			&:before{
				content: '';
				position: absolute;
				width: .1rem;
				height: 12.1rem;
				background: #DDE1E3;
				top: .5rem;
				left: 0;
			} 
		}
		&:first-child{
			padding-top: 2rem;
		}
	}
	&__social-link{
		padding: 1rem;
		font-size: 2rem;
		color: #B5BEBF;
		&:hover{
			color: #FFC002;
		}
	}
	.block-logo{
		margin-bottom: 3rem;
	}
}
/*======================*/
.test-section__wrap-block{
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #5D6E83;
	opacity: .5;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font: 900 1.4rem $base-font;
	max-width: 22.3rem;
	width: 100%;
}
.index-col .test-section__wrap-block{
	max-width: 22.6rem;
}
.test-section__social-link{
	padding: 0 .3rem;
	color: #5D6E83;
	&:hover{
		color: #FFC002;
	}
}
.test-section__wrap-btn{
	margin-bottom: 10.5rem;
}
.test-section__block{
	position: relative;
	&:not(:first-child){
		padding-left: 2.1rem;
		&:before{
			content: '';
			position: absolute;
			width: .2rem;
			height: 1.5rem;
			background: #5D6E83;
			left: 0;
			top: .2rem;
		}
	}
	.view{
		margin-right: 1rem;
		vertical-align: -.1rem;
	}
}
/*------------------------------*/
.all-content{
	position: relative;
	z-index: 1;
	padding: 5rem 0 14rem;
	background: white;
	.index-section__title{
		font: 900 3.6rem/4rem $base-font;
		margin-bottom: 2.7rem;
	}
}
.all-content__left{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	max-width: 88.8rem;
	padding-right: 2.4rem;
	.block-article__link .block-article__social{
		display: none;
	}
}
.all-content__wrap{
	display: flex;
	padding-top: 2rem;
	align-items: flex-start;
}
.all-content__pagination{
	display: flex;
	justify-content: space-between;
	max-width: 22.7rem;
	margin: 9rem auto 0;
	width: 100%;
}
.block-pagination{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4rem;
	height: 4rem;
	box-sizing: border-box;
	border-radius: 50%;
	box-shadow: 30px 4px 70px rgba(16, 86, 122, 0.12);
	color: #B1B6BD;
	font: 900 1.4rem $base-font;
	&:hover{
		border: 2px solid #FFC002;
		color: #FFC002;
	}
}
.block-pagination--active{
	border: 2px solid #FFC002;
	color: #FFC002;
}
.block-article{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 48.8%;
	width: 100%;
	color: rgba(93, 110, 131, 0.5);
	&__link{
		display: block;
		cursor: pointer;
		&:hover &-title{
			color: #FFC002;
		}
		&-title{
			font: 900 2.2rem/2.9rem $base-font;
			color: #5D6E83;
		}
		&-img{
			margin-bottom: 2.3rem;
			width: 100%;
	        height: auto;
		}
	}
	&__wrap-block{
		margin: 2.8rem 0 4rem;
	}
}
.block-aside{
	width: 49.3%;
	box-sizing: border-box;
	&__col{
		text-align: center;
		padding: 19rem 6.3rem;
		&:not(:last-child){
			margin-bottom: 2.4rem;
		}
		&:first-child{
			background: #F8F8F8;
		}
		&:last-child{
			background: #E8F0F9;
		}
	}
	&__title{
		font: 900 2.2rem/2.85rem $base-font;
		color: #5D6E83;
		margin-bottom: 2rem;
	}
	&__text{
		font: 600 1.8rem/2.97rem $base-font;
		color: #7D838C;
	}
	&__btn{
		padding-top: 4.3rem;
	}
	&__p{
		line-height: 2.97rem;
	}
}
.block-aside-faq{
	width: 36rem;
	margin-right: 7.2%;
	.block-faq__col{
		width: 36rem;
		&-list{
			box-shadow: .1rem .4rem 2.5rem rgba(212, 222, 224, 0.55);
		}
		&-list-item{
			padding: 2rem;
			&:hover{
				background: #FFC002;
				a{
					color: white;
				}
			}
		}
		&-list-link{
			display: block;
			font: 2rem/3rem $font-proxima;
			color: #5D6E83;
			.arrow-down{
				display: none;
				float: right;
				margin-top: 1.5rem;
			}
		}
	}
	.current-item{
		background: #FFC002;
		a{
			color: white;
		}
	}
}
/*--------------article-------------------*/
.test-section-article{
	.big-container{
		padding: 20.1rem 0;
	}
	.index-section__title{
		margin-bottom: 0;
	}
}
.all-content-article__wrap{
	padding-top: 0;
	justify-content: space-between;
}
.all-content-article__left{
	max-width: 88rem;
	width: 100%;
	padding-right: 3.2rem;
}
.content-text{
	h3{
		font: 900 2.2rem/2.9rem $base-font;
		color: #5D6E83;
		margin-bottom: 2.2rem;
	}
	p{
		font: 600 1.8rem/2.97rem $base-font;
		color: #7D838C;
		margin-bottom: 1.5rem;
		a{
			color: #0AAFFF;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	p+h3{
		margin-top: 3.5rem;
	}
	p+div{
		display: flex;
		justify-content: space-between;
		padding: 5rem 5.9rem 3.7rem 2.5rem;
		background: #E8F0F9;
		border-radius: 3rem;
		margin: 3.5rem 0 5.2rem;
		>div{
			padding-right: 14%;
			>div{
				position: relative;
				padding-left: 2rem;
				min-height: 5.2rem;
				margin-bottom: 1.3rem;
				a{
					font: bold 1.6rem/2.6rem $base-font;
					color: #5D6E83;
					&:hover{
						text-decoration: underline;
					}
				}
				&:before{
					content: '';
					position: absolute;
					left: 0;
					width: .5rem;
					height: .5rem;
					background: #5D6E83;
					border-radius: 50%;
					top: 1rem;
				}
			}
		}
	}
	blockquote{
		background: #F8F8F8;
		padding: 1.7rem 2.5rem 1.7rem 2.9rem;
		font: 600 1.8rem/3rem $base-font;
		color: #5D6E83;
		margin-bottom: 2rem;
	}
	ul{
		padding-top: .6rem;
		margin: 0 0 2rem 2rem;
		li{
			position: relative;
			font: 600 1.8rem/2.97rem $base-font;
			color: #7D838C;
			padding-left: 2rem;
			&:before{
				content: '';
				position: absolute;
				left: 0;
				width: .8rem;
				height: .8rem;
				background: #5D6E83;
				border-radius: 50%;
				top: 1.2rem;
			}
		}
	}
}
.content-article{
	&__wrap-block{
		margin-bottom: 3.2rem;
	}
	&__wrap-info{
		position: relative;
		max-width: 100%;
		padding-top: 2.6rem;
		margin-top: 3.2rem;
		&:before{
			content: '';
			position: absolute;
			width: 100%;
			height: .1rem;
			background: #D6DBE0;
			opacity: 0.5;
			top: 0;
		}
	}
	&__img{
		width: 100%;
		height: auto;
		margin-bottom: 2.5rem;
	}
	&__block{
		padding: 0;
		&:before{
			display: none;
		}
	}
}
.all-tearms .index-section__title, .all-faq .index-section__title{
	margin-bottom: 5rem;
}
.all-tearms__wrap{
	padding-top: 0;
}
.content-tearms{
	&__title{
		font: 600 2.5rem/3.7rem $base-font;
		color: #292B34;
		margin: 2.3rem 0 1.6rem;
	}
	&__list{
		margin-bottom: 6rem;
		li a{
			color: #0AAFFF;
			line-height: 2.3rem;
			&:hover{
				text-decoration: underline;
			}
		}
	}
}
.all-faq__left{
	padding-right: 0;
}
.content-faq{
	&__title{
		margin-top: 0;
	}
	&__text{
		counter-reset: item;
		h3{
			position: relative;
			font: 600 2.5rem/3.7rem $base-font;
			color: #292B34;
			margin-bottom: 2rem;
			&:before{
				content: counter(item)'.';
				counter-increment: item;
			}
		}
		p{
			line-height: 2.3rem;
			color: #292B34;
			margin-bottom: 4.5rem;
		}
		ul{
			margin: -1.6rem 0 2.5rem;
			li{
				position: relative;
				line-height: 2.3rem;
				color: #292B34;
				padding-left: 1.2rem;
				margin-bottom: 1rem;
				&:before{
					content: '';
					position: absolute;
					left: 0;
					width: .5rem;
					height: .5rem;
					background: #292B34;
					border-radius: 50%;
					top: 1rem;
				}
			}
		}
		ul+h3{
			margin-bottom: 2.5rem;
		}
		div{
			position: relative;
			padding-bottom: 56.25%;
			padding-top: 30px;
			height: 0;
			overflow: hidden;
			margin-bottom: 3.5rem;
			iframe{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
		img{
			width: 100%;
			height: auto;
		}
	}
}
/*---------------------------*/
.similar-articles{
	padding-top: 0;
	&__wrap{
		max-width: 100%;
		padding-right: 0;
		justify-content: flex-start;
	}
}
.similar-article{
	padding: 6rem 0 0;
	max-width: 43.2rem;
	margin-right: 2.4rem;
	.block-article__link &__wrap-block{
		display: none;
	}
	&__wrap-block{
		margin: 2.8rem 0 0;
	}
	&:last-child{
		margin-right: 0;
	}
}
/*-----------slider-owl-----------------*/
.owl-carousel{
	position: static;
}
.owl-theme .owl-dots{ 
	position: absolute;
	left: 1rem;
	z-index: 100;
	bottom: 10rem;
	.owl-dot{
		span{
			width: 4.8rem;
			height: .4rem;
			border-radius: none;
			background: #B1B6BD;
		}
		&:hover span{
			background: #FFC002;
		}
	}
	.owl-dot.active span{
		background: #FFC002;
	}
} 
/*-------------------forms-------------------*/
.index-section-form{
	padding: 2.5rem 0 14rem;
	.index-row{
		align-items: flex-start;
	}
	.index-col{
		padding-top: 13.5rem;
	}
}
.block-form{	
	width: 54.6rem;
	box-sizing: border-box;
	padding: 4.8rem 7.3rem;
	box-shadow: .5rem .5rem 4rem #edeff2;
	background: white;
	margin-left: 2rem;
	&__text{
		text-align: center;
		font: bold 1.8rem/3rem $base-font;
		color: #5D8ED6;
		letter-spacing: 0.005em;
		margin-bottom: 3.8rem;
	}
	.block-form__title{
		text-align: center;
		margin-bottom: 1.5rem;
	}
	&__wrap-btn{
		padding-top: 2rem;
		.main-btn--small{
			padding: 1.5rem 7rem;
		}
	}
	&__col{
		position: relative;
		margin-bottom: 2.5rem;
		&-text{
			font: 400 1.8rem/1.8rem $base-font;
			letter-spacing: 0.005em;
			color: #748394;
			&:hover{
				color: #FFC002;
			}
		}
	}
}
.block-forgot{
	text-align: right;
}
.main-input__label{
	&-text{
		display: block;
		font: 600 1.6rem/2.6rem $base-font;
		color: #748394;
		margin-bottom: 1rem;
	}
	&-input{
		border: 1px solid rgba(177, 182, 189, 0.6);
		box-sizing: border-box;
		border-radius: 1rem;
		width: 40rem;
		height: 6.5rem;
		font: 600 1.6rem/2.6rem $base-font;
		color: #5D6E83;
		padding: 1.9rem 4.5rem 2rem 2rem;
		&:focus{
			border: 1px solid #FFC002;
		}
	}
}
.wrapper-form-forgot{
	margin: 0 auto;
}
.block-form-forgot{
	margin-left: 0;
}
