$font-path : '../fonts/';

@font-face {
  font-family: 'Muli';
  src:  url('#{$font-path}Muli/Muli-Regular.eot?#iefix') format('embedded-opentype'),  
        url('#{$font-path}Muli/Muli-Regular.woff') format('woff'), 
        url('#{$font-path}Muli/Muli-Regular.ttf')  format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src:  url('#{$font-path}Muli/Muli-SemiBold.eot?#iefix') format('embedded-opentype'),  
  		  url('#{$font-path}Muli/Muli-SemiBold.woff') format('woff'), 
  		  url('#{$font-path}Muli/Muli-SemiBold.ttf')  format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src:  url('#{$font-path}Muli/Muli-Bold.eot?#iefix') format('embedded-opentype'),  
        url('#{$font-path}Muli/Muli-Bold.woff') format('woff'), 
        url('#{$font-path}Muli/Muli-Bold.ttf')  format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src:  url('#{$font-path}Muli/Muli-ExtraBold.eot?#iefix') format('embedded-opentype'),  
        url('#{$font-path}Muli/Muli-ExtraBold.woff') format('woff'), 
        url('#{$font-path}Muli/Muli-ExtraBold.ttf')  format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Muli';
  src:  url('#{$font-path}Muli/Muli-Black.eot?#iefix') format('embedded-opentype'),  
        url('#{$font-path}Muli/Muli-Black.woff') format('woff'), 
        url('#{$font-path}Muli/Muli-Black.ttf')  format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('#{$font-path}ProximaNova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),  
       url('#{$font-path}ProximaNova/ProximaNova-Regular.woff') format('woff'), 
       url('#{$font-path}ProximaNova/ProximaNova-Regular.ttf')  format('truetype'), 
       url('#{$font-path}ProximaNova/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-proxima: 'ProximaNova', sans-serif;
$base-font: 'Muli', sans-serif;
